import React from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';

interface IBenefitCard {
  heading: string;
  description: string;
  icon: string;
  classIdentifier: string;
  linkUrl: string;
}

const BenefitCard = ({ heading, description, icon, classIdentifier, linkUrl }: IBenefitCard) => {
  const { width } = useWindowSize();
  if (width === 0) return null;
  return (
    <div className={`new-benefits__card ${classIdentifier}`}>
      <a className="new-benefits__card-link" href={linkUrl}>
        <div className="new-benefits__card-heading-section">
          <div
            className={`new-benefits__card-icon-box ${classIdentifier}`}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
          <h3 className="new-benefits__card-heading">{heading}</h3>
        </div>
        <p className="new-benefits__card-content" dangerouslySetInnerHTML={{ __html: description }} />
      </a>
    </div>
  );
};

export default BenefitCard;
